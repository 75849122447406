import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { TextButton } from 'wix-ui-tpa/cssVars';
import { useVisibilityExperiment } from '../../../../../hooks/useVisibilityExperiment';
import settingsParams from '../../../../../settingsParams';
import { classes, st } from './MoreInfoButton.st.css';
import { DataHooks } from './consts';

export interface MoreInfoButtonProps {
  onClick: () => void;
}

export const MoreInfoButton: FC<MoreInfoButtonProps> = ({ onClick }) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();
  const { addVisibilityClass } = useVisibilityExperiment();

  return (
    <TextButton
      onClick={onClick}
      className={addVisibilityClass(
        st(classes.root, { isMobile }),
        classes.moreInfoButtonVisibility,
        'isMoreInfoButtonVisible',
      )}
      data-hook={DataHooks.ROOT}
    >
      {settings.get(settingsParams.moreInfoButtonText) ||
        t('info-card.design.more-info-label')}
    </TextButton>
  );
};
