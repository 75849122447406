import React from 'react';
import { useWidgetViewModel } from '../hooks/useWidgetViewModel';
import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { DataHooks } from './consts';

interface Map {
  [key: string]: boolean;
}

export const HiddenServicesForSeo = () => {
  const { services, seo } = useWidgetViewModel();
  const [hiddenServices] = React.useState(() => {
    const serviceToDisplayMap = services.reduce(
      (map: Map, service: CatalogServiceDto) => {
        map[service.id] = true;
        return map;
      },
      {},
    );
    return seo!.allServices.filter(
      (service) => !serviceToDisplayMap[service.id],
    );
  });

  return (
    <ul data-hook={DataHooks.ROOT} style={{ display: 'none' }}>
      {hiddenServices.map((service) => (
        <li key={service.id}>
          <a data-hook={DataHooks.LINK} href={service.fullUrl}>
            {service.info.name}
          </a>
        </li>
      ))}
    </ul>
  );
};
