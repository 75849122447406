import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import settingsParams from '../settingsParams';
import { VisibilityKey } from './types';

export function useVisibilityExperiment() {
  const { experiments } = useExperiments();
  const { isCssPerBreakpoint } = useEnvironment();
  const settings = useSettings();
  const isVisibilityStyleParamsEnabled =
    experiments.enabled('specs.bookings.VisibilityStyleParams') &&
    isCssPerBreakpoint;

  return {
    isVisibilityStyleParamsEnabled,
    addVisibilityClass: (
      className: string,
      visibilityClassName: string,
      key: VisibilityKey,
    ) =>
      isVisibilityStyleParamsEnabled &&
      typeof settings.get(settingsParams[key]) !== 'boolean'
        ? className
          ? `${className} ${visibilityClassName}`
          : visibilityClassName
        : className,
  };
}
